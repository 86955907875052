.Description-container {
    margin: 0 auto;
    text-align: center;
    width: 90vw;
    height: 95%;
    background-image: url('../../images/old-paper-background-for-microsoft-word-diy-pinte-within-blank-old-newspaper-background-600x600.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
    font-size: 16pt;
    padding: 3%;
}

.signup-button {
    margin: 0 0 0 10px;
}

.signup-button, .login-button {
    height: 70%;
    line-height: 70%;
    text-decoration: none;
    padding: 1.5%;
}

.inner-description-text, .outer-description-text {
    font-size: 4vmin;
}

.outer-description-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    min-width: 60%;
}

.inner-description-text {
    padding: 0;
    margin: 0;
    text-align: left;
}

@media only screen and (min-width: 700px) {
    .outer-description-text, .inner-description-text {
        font-size: 2.5vmin; 
    }

}

@media only screen and (min-width: 1100px) {
    .signup-button, .login-button {
        font-size: 1.5vmin;
    }

    .outer-description-text, .inner-description-text {
        font-size: 3vmin; 
    }

}
