.Main-container {
    margin: 0 auto;
    text-align: center;
    width: 90vw;
    max-width: 700px;
    overflow: scroll;
    height: 95%;
    overflow-x: hidden;
}

.sort-popular {
    margin: 0 0 0 10px;
}

.sort-created, .sort-popular {
    height: 70%;
    line-height: 70%;
}

@media only screen and (min-width: 500px) {
    .sort-created, .sort-popular {
        font-size: 2vmin;
    }
}