.Comments-input-section {
    height: 20%;
    text-align: center;
}

.Comments-input-section input {
    width: 76%;
    border-radius: 5px;
    margin: 0 0 15px 0;
}

.Comment {
    position: relative;
    min-height: 30px;
    margin: 1% auto 0;
    padding: 1% 7%;
    font-size: 10pt;
    overflow-wrap: break-word;
    overflow: hidden;
    color: black;
    width: 86%;
}

.Comment:after {
    content:""; 
    background: black; 
    position: absolute; 
    bottom: 0; 
    left: 7%; 
    width: 86%; 
    height: 1px;
}

.Comment-username {
    font-style: italic;
    text-align: right;
    font-size: 10pt;
    font-family: 'News Cycle', sans-serif;
}

.comments-placeholder {
    padding: 0 0 0 12%;
}