main {
  height: 93vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App, .App-background-image {
  width: 100%;
  height: 100%;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
}

.App-background-image {
  background-image: url('images/vector-seamless-pattern-newspaper-can-be-used-wallpaper-web-background-scrapbooking-fabrics-101632311.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .7;
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

section {
  height: 100%;
}

.toolbar {
  height: 5%;
  display: flex;
  margin: 0 auto;
  width: 60%;
  justify-content: center;
}

button, a {
  border: 3px solid #fff;
  border-radius: 15px;
	font-family: inherit;
	font-size: 10pt;
	color: black;
	background-color: rgb(128, 128, 128, 0.25);
	cursor: pointer;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: .5px;
	font-weight: 700;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

button:before, a:before {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

button:after, a:after {
  content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background: #fff;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

button:hover,
button:active, a:hover, a:active {
	color: rgb(177, 150, 0);;
	background: #fff;
}