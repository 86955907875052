.App-header {
    height: 7vh;
    color: rgb(177, 150, 0);
    text-shadow: 1px 1px 1px black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
}

h1 {
    font-family: 'Cinzel', serif;
    margin: 0;
    white-space: nowrap;
    font-size: 7vmin;
}

.home-container {
    width: 50%;
    max-width: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-buffer {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.write-new-article-button {
    margin: 2%;
    font-size: 3vmin;
}

.logout-button, .write-new-article-button {
    width: 96%;
    max-width: 150px;
}

@media only screen and (min-width: 500px) {
    .write-new-article-button {
        font-size: 2.5vmin;
    }
}

@media only screen and (min-width: 800px) {
    .write-new-article-button {
        font-size: 2vmin;
    }
}

@media only screen and (min-width: 1100px) {
    .write-new-article-button {
        font-size: 1.5vmin;
    }
    
    .write-article-container {
        display: flex;
        justify-content: flex-end;
    }

    .logout-container {
        display: flex;
        justify-content: flex-start;
    }

    h1 {
        font-size: 5.5vmin;
    }    
}