.Login-page {
    height: 100%;
}

.Login-container {
    background-image: url('../../images/old-paper-background-for-microsoft-word-diy-pinte-within-blank-old-newspaper-background-600x600.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
}

.Login-page form {
    padding: 10% 0 0 0;
    margin: 0 auto;
}

.Login-page fieldset {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    border: none;
    padding: 0 10%;
}

.Login-page label, .Login-page input {
    margin: 0;
    text-align: start;
    width: 100%;
    font-size: 4.5vmin;
}

.Login-page label {
    padding: 1% 0 0 0;
}

.Login-page input {
    padding: 1% 0 0 1%;
}

.Login-page legend {
    margin: 0 0 5% 0;
    font-size: 6vmin;
}

.Login-page button {
    font-size: 4.5vmin;
}

.Login-page p {
    padding: 3% 0 0 1%;
    font-size: 3vmin;
}