.Article {
    height: auto;
    width: 100%;
    margin: 0 0 2vh 0;
    text-align: left;
    background-image: url('../../images/old-paper-background-for-microsoft-word-diy-pinte-within-blank-old-newspaper-background-600x600.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
}

.Article-headline {
    position: relative;
    text-align: center;
    font-size: 16pt;
    overflow: hidden;
    padding: 1% 0;
    box-shadow: 0px 24px 3px -24px black;
    font-family: 'Cinzel', serif;
}

.Article-print-container {
    font-family: 'News Cycle', sans-serif;
    font-size: 12pt; 
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;  
    white-space: -pre-wrap; 
    white-space: -o-pre-wrap;  
    word-wrap: break-word;
    column-count: 2;
    column-gap: 5%;
    overflow-wrap: break-word;
    padding: 3% 7%;
    min-height: 70px;
}

.Article-print-container::first-letter {
    font-size: 200%;
    line-height: .5em;
    padding: 0 .5px 0 0;
}

.Article-print-container.columns {
    orphans: 2;
}

.Article p {
    margin: 0;
}

.Article-username {
    font-style: italic;
    text-align: right;
    font-size: 10pt;
    padding: 0 7% 0 0;
    font-family: 'News Cycle', sans-serif;
}

.Article form {
    padding: 0;
}

.Article-container {
    box-shadow: 0px 24px 3px -24px black;
    margin: 0 0 5px 0;
    padding: 0 0 5px 0;
}


