.Account-signup-container {
  display: flex;
  flex-direction: column;
  padding: 5% 0 0 0;
  margin: 0 auto;
  width: 90vw;
  height: 95%;
  background-image: url('../../images/old-paper-background-for-microsoft-word-diy-pinte-within-blank-old-newspaper-background-600x600.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.Account-signup-container form {
  margin: 0 auto;
  text-align: center;
}

.Account-signup-container fieldset {
  display: flex;
  flex-direction: column;
  margin: 0;
  border: none;
  padding: 0 10%;
}

.Account-signup-container label, .Account-signup-container input {
  padding: 1% 0 0 1%;
  margin: 0;
  text-align: start;
  width: 100%;
  font-size: 4.5vmin;
}

.Account-signup-container legend {
  margin: 0 0 5% 0;
  font-size: 6vmin;
}

.Account-signup-container button {
  font-size: 4.5vmin;
}

.Account-signup-container p {
  padding: 2%;
}

@media only screen and (min-width: 420px) {
  .Account-signup-container label, .Account-signup-container input {
    font-size: 4vmin;
  }
}

@media only screen and (min-width: 800px) {
  .Account-signup-container label, .Account-signup-container input {
    font-size: 3vmin;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1100px) {
  .Account-signup-container label, .Account-signup-container input {
    font-size: 2vmin;
  }
}