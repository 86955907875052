
.Write-article-container {
    background-image: url('../../images/old-paper-background-for-microsoft-word-diy-pinte-within-blank-old-newspaper-background-600x600.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
}

.Write-article-page form {
    padding: 10% 0 0 0;
}

.Write-article-page fieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 0;
    border: none;
}

.Write-article-page label, .Write-article-page input,.Write-article-page  textarea {
    padding: 1% 0 0 1%;
    margin: 0;
    width: 100%;
    text-align: start;
}

.Write-article-page ::placeholder {
    padding: 0 0 10%;
}

.Write-article-page textarea::placeholder {
    font-size: 4.5vmin;
}

.Write-article-page legend {
    font-size: 6vmin;
}

.Article-headline-input {
    height: 5vh;
}

.Article-print-input {
    height: 30vh;
    margin: 0;
}

.Write-article-page button {
    margin: 5% 0 0 0;
}

.Write-article-page p {
    margin: 0;
    align-self: center;
}

.red {
    color: red;
}

.yellow {
    color: rgb(223, 223, 24);
    text-shadow: 1px 1px 1px black;
}

.green {
    color: green;
}

.articles-left-text {
    font-size: 16pt;
    line-height: 30px;
}

.articles-left-number {
    font-size: 20pt;
}

.giant-zero-image {
    padding: 1% 0;
    height: 100%;
    width: 25px;
}

@media only screen and (min-width: 420px) {
    .Write-article-page textarea::placeholder {
        font-size: 3vmin;
    }

    .Write-article-page legend {
        font-size: 5vmin;
    }
}

@media only screen and (min-width: 800px) {
    .Write-article-page textarea::placeholder {
        font-size: 2vmin;
    }

    .Write-article-page legend {
        font-size: 4vmin;
    }
}

@media only screen and (min-width: 1100px) {
    .Write-article-page textarea::placeholder {
        font-size: 1.5vmin;
    }

    .Write-article-page legend {
        font-size: 3vmin;
    }
}